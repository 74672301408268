<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <el-button type="primary" size="small" @click="teacherShow = true">添加商品</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="商品名称" prop="goodsName"></el-table-column>
        <el-table-column align="center" label="封面图">
          <template slot-scope="scope">
            <el-image style="width: 100px;" :src="scope.row.goodsCoverImg" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品价格" prop="price"></el-table-column>
        <el-table-column align="center" label="商品会员价" prop="vipPrice"></el-table-column>
        <el-table-column align="center" label="商品库存" prop="stockCount"></el-table-column>
        <el-table-column align="center" label="商品排序" prop="rankSort"></el-table-column>
        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="success" @click="$router.push({path:`/goodsInfo?id=${scope.row.id}`})" icon="el-icon-edit"
                       size="mini">详情
            </el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemoveGoods(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加或编辑弹框 -->
    <el-dialog top="3vh" :title="form.id ? '编辑商品' : '添加商品'" :visible.sync="teacherShow" width="60%"
               :before-close="handleClose">
      <!-- 表单 -->
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm" :rules="rules">
        <div class="layout_sb">
          <el-form-item label="商品名称:" prop="goodsName">
            <el-input @focus="clearValidate('goodsName')" v-model="form.goodsName" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="封面图:" prop="goodsCoverImg">
            <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                       :on-success="successImages">
              <img v-if="form.goodsCoverImg" :src="form.goodsCoverImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品描述:" prop="goodsDesc">
            <el-input @focus="clearValidate('goodsDesc')" type="textarea" :rows="2" v-model="form.goodsDesc"
                      placeholder="请输入商品描述"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sb">
          <el-form-item label="商品价格:" prop="price">
            <el-input @focus="clearValidate('price')" type="number" v-model="form.price"
                      placeholder="请输入商品价格"></el-input>
          </el-form-item>
          <el-form-item label="会员价格:" prop="vipPrice">
            <el-input @focus="clearValidate('vipPrice')" type="number" v-model="form.vipPrice"
                      placeholder="请输入会员价格"></el-input>
          </el-form-item>
          <el-form-item label="商品库存:" prop="stockCount">
            <el-input @focus="clearValidate('stockCount')" type="number" v-model="form.stockCount"
                      placeholder="请输入商品库存"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sb banner" style="align-items: flex-start;">
          <el-form-item label="商品排序:" prop="rankSort">
            <el-input @focus="clearValidate('rankSort')" type="number" v-model="form.rankSort"
                      placeholder="请输入商品排序"></el-input>
          </el-form-item>
          <el-form-item label="商品轮播图:" prop="goodsImgList" style="flex:2;">
            <el-upload ref="uploadRef" :file-list="fileList" :limit="limit" action="/api/file/upload"
                       list-type="picture-card" :on-progress="handlerProgress"
                       :on-remove="handlerRemove" :on-preview="handlePictureCardPreview" :on-exceed="handlerExceed">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog top="8vh" :visible.sync="dialogVisible" :modal="false">
              <img width="100%" :src="diaflogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </div>
        <el-form-item label="商品详情:" prop="goodsInfo">
          <wang-editor :height="350" ref="editor"></wang-editor>
        </el-form-item>
        <el-form-item label="状态:" style="margin-top:10px;">
          <el-switch v-model="statusOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="handleClose">取 消</el-button>
                <el-button size="mini" type="primary" @click="submit">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import WangEditor from "../../components/wangeditor/wangeditor.vue"
import {goodsPage, addOnSet, delGoods} from '../../apis/goods.js'
import {goods} from "@/utils/rules"

export default {
  components: {WangEditor},
  data() {
    return {
      query: {
        current: 1,
        size: 10,
      },
      tableData: [],
      loading: false,
      total: null,
      teacherShow: false,
      statusOpen: true,
      rules: goods.rules,
      form: {
        goodsName: "",	// 商品名称
        goodsCoverImg: "",	// 商品封面图
        goodsDesc: "",	// 商品描述
        price: "",	// 商品价格
        vipPrice: "",// 会员价
        stockCount: "",	// 当前库存
        rankSort: "",// 	排序值
        goodsImgList: "",	// 商品轮播图
        goodsInfo: "",	// 商品详情
        status: "",	// 状态(0=上架 1 =下架)
      },
      diaflogImageUrl: '', // 预览图片
      dialogVisible: false,
      fileList: [],
      limit: 3
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 文件超出限制
    handlerExceed() {
      this.$message.warning(`当前只能上传 ${this.limit} 张图片。`);
    },
    // 删除文件的钩子
    handlerRemove(file, fileList) {
      console.log("12321", file, fileList)
      this.fileList = fileList
    },
    // 上传时的钩子
    handlerProgress(event, file, fileList) {
      this.fileList = fileList;
    },
    // 预览图片的钩子
    handlePictureCardPreview(file) {
      this.diaflogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },

    handleClose() {
      this.teacherShow = false
      this.clearCen()
    },

    handlerEdit(item) {
      this.teacherShow = true
      const {goodsInfo, status, goodsImgList} = item
      const bannerArr = goodsImgList.split(',')
      bannerArr.forEach(element => {
        this.fileList.push({
          url: element
        })
      });
      delete item.version
      delete item.saleCount
      delete item.createTime
      const newObj = {...item}
      this.form = newObj
      this.statusOpen = [true, false] [status]
      this.$nextTick(() => {
        this.$refs.editor.setContent(goodsInfo)
      })
    },
    handlerRemoveGoods(id) {
      this.$confirm('此操作将永久商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await delGoods({id})
        this.$message({message: '删除成功', type: "success"})
        this.getData()
      }).catch(err => {
      })
    },
    submit() {
      this.form.goodsInfo = this.$refs.editor.getContent()
      // 处理上传轮播图的图片
      console.log("12312", this.fileList)
      const bannerArr = this.fileList.length && this.fileList.map(item => item.response && item.response.data || item.url)
      this.form.goodsImgList = bannerArr.length ? bannerArr.join(',') : ''
      this.$refs.form.validate(async val => {
        if (!val) return
        this.form.status = this.statusOpen ? 0 : 1
        await addOnSet(this.form)
        this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: "success"});
        this.teacherShow = false
        this.getData()
        this.clearCen()
      })
    },

    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.statusOpen = true
      this.fileList = []
      this.$refs.editor.clearContent()
      delete this.form.id // 删除对象里面的id
    },

    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.goodsCoverImg = response.data
      this.$refs.form.clearValidate('goodsCoverImg');
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },

    async getData() {
      this.loading = true
      const res = await goodsPage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 10px !important;
}

.el-form-item {
  flex: 1;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 60px;
  border: 1px dashed #999;
}

.avatar {
  width: 140px;
  height: 60px;
  display: block;
}

/deep/ .el-upload--picture-card,
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  line-height: 80px;
  height: 80px !important;
}
</style>